import http from '../clients/Axios'

export default class SupplierService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/supplier/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/supplier${param}`)
    return res
  }

  async add(form) {
    return await http.post('/supplier', form)
  }

  async update(form) {
    return await http.post(`/supplier/${form.id}`, form)
  }

  async delete(id) {
    return await http.delete(`/supplier/${id}`)
  }

  async downloadFormSpesimen() {
    return http({
      url: '/supplier/spesimen',
      method: 'GET',
      responseType: 'blob',
    })
  }

  async downloadSpesimen(form) {
    return http({
      url: form.spesimen,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods':
          'POST, GET, DELETE, PUT, PATCH, OPTIONS',
      },
      responseType: 'blob',
    })
  }

  async upload(id, value) {
    let formData = new FormData()

    if (value) {
      formData.append('spesimen', value)
    }

    return await http.post(`/supplier/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/supplier/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
